<script>
import Layout from "../../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";

export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      accounts: [],
      levelTwoAccounts: [],
      levelThreeAccountId: null,
      depreciationExpense: [],
      limit: 20,
      page: 1,
    };
  },
  methods: {
    get(page) {
      this.http
        .post("fixed-assets/types/setup/pagination", {
          page,
          limit: this.limit,
        })
        .then((res) => {
          if (res.status) {
            this.page = page;
            this.tot_pages = Math.ceil(res.tot / this.limit);
            this.accounts = res.data;
          }
        });
    },
    selectedAccount(account) {
      let type = "Asset";
      let level = 2;
      if (account.name_en == "Depreciation & Amortization Expense") {
        level = 3;
        type = "Expense";
      }
      if (account.name_en == "Asset Equity") {
        level = 3;
        type = "Equity";
      }
      if (account.name_en == "Gain and Loss") {
        level = 3;
        type = "";
      }
      this.http
        .post("level-three-chart-of-accounts-copy-1/accounts-setup/search", {
          search: account.search,
          level,
          type,
        })
        .then((res) => {
          if (res.status) {
            this.levelTwoAccounts = res.data.map((a) => {
              return this.$i18n.locale == "ar"
                ? a.code + "-" + a.name
                : a.code + "-" + a.name_en;
            });
          }
        });
    },
    editAccount(account) {
      this.http.post("fixed-assets/types/setup/edit", account).then((res) => {
        if (res.status) {
          this.levelTwoAccounts = [];
          this.get(this.page);
        }
      });
    },
    searchAccounts(account) {
      console.log(account);
      this.levelThreeAccountId = account?.search?.split("-")[0];
      console.log(this.levelThreeAccountId);
      this.levelTwoAccounts = [];
      this.http
        .post("level-three-chart-of-accounts-copy-1/accounts-setup", {
          leveThreeId: this.levelThreeAccountId,
          id: account.id,
        })
        .then((res) => {
          if (res.status) {
            console.log("done");
            account.search = "";
            this.getDepreciationExpense();
          }
        });
    },
    getDepreciationExpense() {
      this.http
        .post("fixed-assets/get-depreciation-expense", {})
        .then((res) => {
          if (res.status) {
            this.depreciationExpense = res.data;
          }
        });
    },
  },
  computed: {
    filteredAccounts() {
      return this.levelTwoAccounts.filter((account) => {
        return account;
      });
    },
  },
  created() {
    this.get(1);
    this.getDepreciationExpense();
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="$t('fixed_assets.setup')" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <table
                class="table table-centered table-nowrap table-striped table-hover align-middle"
              >
                <thead>
                  <tr
                    class="text-light"
                    style="background-color: #2a3042 !important"
                  >
                    <th scope="col">#</th>
                    <!-- <th scope="col">{{ $t("fixed_assets.module") }}</th> -->
                    <th scope="col">{{ $t("fixed_assets.module_name") }}</th>
                    <th scope="col">
                      {{ $t("popups.code") }}
                    </th>
                    <th scope="col">
                      {{ $t("fixed_assets.account_name") }}
                    </th>
                    <th scope="col">
                      {{ $t("fixed_assets.operations") }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(account, index) in accounts" :key="account">
                    <td>{{ index + 1 }}</td>
                    <td>
                      {{
                        $i18n.locale == "ar" ? account.name : account.name_en
                      }}
                    </td>
                    <td>{{ account?.code }}</td>
                    <td>
                      {{
                        $i18n.locale == "ar"
                          ? account?.l2_name
                          : account?.l2_name_en
                      }}
                    </td>
                    <td>
                      <div class="d-flex">
                        <AutoComplete
                          @keyup="selectedAccount(account)"
                          v-model="account.search"
                          :suggestions="filteredAccounts"
                          @complete="account.search = $event.query"
                          style="
                            color: #333 !important;
                            border-radius: 10px !important;
                          "
                        />
                        <button
                          style="margin-inline-start: 8px"
                          @click="editAccount(account)"
                          type="button"
                          class="btn btn-primary"
                        >
                          {{ $t("popups.edit") }}
                        </button>
                      </div>
                    </td>
                  </tr>
                  <tr v-for="expense in depreciationExpense" :key="expense">
                    <td>{{ accounts.length + 1 }}</td>
                    <td>
                      {{
                        $i18n.locale == "ar" ? expense?.name : expense?.name_en
                      }}
                    </td>
                    <td>{{ expense?.code }}</td>
                    <td>
                      {{
                        $i18n.locale == "ar"
                          ? expense?.l3_name
                          : expense?.l3_name_en
                      }}
                    </td>
                    <td>
                      <div class="d-flex">
                        <AutoComplete
                          @keyup="selectedAccount(expense)"
                          v-model="expense.search"
                          :suggestions="filteredAccounts"
                          @complete="expense.search = $event.query"
                          style="
                            color: #333 !important;
                            border-radius: 10px !important;
                          "
                        />
                        <button
                          style="margin-inline-start: 8px"
                          @click="searchAccounts(expense)"
                          type="button"
                          class="btn btn-primary"
                        >
                          {{ $t("popups.edit") }}
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- end  table -->
            <!-- Button trigger modal -->

            <!--   Apps  Pagination     -->
            <ul
              class="pagination pagination-rounded justify-content-center mb-2"
            >
              <li class="page-item" :class="{ disabled: page == 1 }">
                <a
                  class="page-link"
                  href="javascript: void(0);"
                  @click="get(page - 1)"
                  aria-label="Previous"
                >
                  <i class="mdi mdi-chevron-left"></i>
                </a>
              </li>
              <li
                class="page-item"
                :class="{ active: p == page }"
                v-for="p in tot_pages"
                :key="p"
              >
                <a
                  class="page-link"
                  href="javascript: void(0);"
                  @click="get(p)"
                  >{{ p }}</a
                >
              </li>

              <li class="page-item" :class="{ disabled: page == tot_pages }">
                <a
                  class="page-link"
                  href="javascript: void(0);"
                  @click="get(page + 1)"
                  aria-label="Next"
                >
                  <i class="mdi mdi-chevron-right"></i>
                </a>
              </li>
            </ul>
            <!--end-->
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
